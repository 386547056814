<template>
  <div class="text-sm">
    <button class="flex w-full items-center justify-between" @click="toggle">
      <span class="font-bold uppercase tracking-wider">
        {{ data.page.title }}
      </span>
      <IconPlus class="w-4" />
    </button>

    <TransitionExpand :open="isOpen">
      <ul class="my-4 w-full space-y-1.5 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
        <li v-for="({ page }, index) in data.children" :key="index">
          <NuxtLink :to="page.url">{{ page.title }}</NuxtLink>
        </li>
      </ul>
    </TransitionExpand>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  data: { type: Object, required: true },
})

const isOpen = ref(false)

const toggle = () => {
  isOpen.value = !isOpen.value
}
</script>
