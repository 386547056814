<template>
  <div>
    <p class="mb-4 font-bold xl:mb-8">Newsletter</p>

    <form class="-m-4 flex flex-wrap" @submit.prevent="submit">
      <div class="relative w-1/2 p-4">
        <InputText
          v-model="firstName"
          :label="$t('form.label.firstName')"
          :placeholder="$t('form.label.firstName')"
          required
        />
      </div>

      <div class="relative w-1/2 p-4">
        <InputText
          v-model="lastName"
          :label="$t('form.label.lastName')"
          :placeholder="$t('form.label.lastName')"
          required
        />
      </div>

      <div class="relative w-full p-4">
        <InputText
          v-model="email"
          :label="$t('form.label.email')"
          :placeholder="$t('form.label.email')"
          type="email"
          required
        />
      </div>

      <AppButton size="sm" class="ml-4 mt-2 lg:ml-4">
        {{ $t('form.login.title') }}
      </AppButton>

      <Transition name="fade">
        <div v-if="submitted" class="w-full p-4">
          <p
            class="inline-block rounded-sm bg-green-600 p-4 text-sm font-medium text-white"
          >
            {{ $t('form.newsletterSuccess') }}
          </p>
        </div>
      </Transition>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ApiClientError } from '@shopware/api-client'

const { t } = useI18n()
const emit = defineEmits(['subscribe-newsletter'])
const { pushSuccess, pushError } = useNotifications()
const { apiClient } = useShopwareContext()
const { transformError } = useShopwareError()
const loading = ref(false)
const firstName = ref('')
const lastName = ref('')
const email = ref('')
const submitted = ref(false)

const submit = async () => {
  try {
    await apiClient.invoke(
      'requestBillingAddressChange post teamnovu/newsletter-subscribe',
      {
        body: {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
        },
      }
    )
    emit('subscribe-newsletter')
    pushSuccess(t('form.newsletterSuccess'))
  } catch (exception) {
    if (exception instanceof ApiClientError) {
      const errors = transformError(exception)
      pushError(errors[0], { persistent: true })
    }
  } finally {
    loading.value = false
  }
}
</script>
