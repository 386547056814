<template>
  <TransitionGroup
    name="fade"
    mode="out-in"
    tag="div"
    class="flex flex-col items-start gap-2"
  >
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="inline-flex rounded-sm border px-4 py-2 font-semibold shadow-md lg:first-of-type:mt-2"
      :class="{
        'border-green-200 bg-green-100 text-green-700':
          notification.type === 'success',
        'border-red-200 bg-red-50 text-red-700': notification.type === 'danger',
        'bg-black text-white ':
          notification.type !== 'success' && notification.type !== 'danger',
      }"
    >
      <div class="flex items-start gap-3">
        <div class="max-lg:mt-1">
          <IconCheck
            v-if="notification.type === 'success'"
            class="size-5 shrink-0 rounded-full bg-green-700 p-0.5 text-white"
          />
          <IconX
            v-if="notification.type === 'danger'"
            class="size-5 shrink-0 rounded-full bg-red-700 p-1 text-white"
          />
        </div>
        <span
          class="break-words text-sm"
          :style="{ 'overflow-wrap': 'anywhere' }"
        >
          {{ notification.message }}
        </span>
        <button
          type="button"
          class="flex h-full shrink-0 items-center"
          @click="removeOne(notification.id)"
        >
          <IconX
            class="ml-auto size-4 cursor-pointer"
            :class="{
              'text-green-700': notification.type === 'success',
              'text-red-700': notification.type === 'danger',
              'text-white':
                notification.type !== 'success' &&
                notification.type !== 'danger',
            }"
          />
        </button>
      </div>
    </div>
  </TransitionGroup>
</template>

<script setup lang="ts">
const { notifications, removeOne } = useNotifications()
</script>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
