<template>
  <div class="flex flex-wrap items-center gap-4 text-gray-400">
    <IconPaymentTwint class="h-6" />
    <IconPaymentVisa class="h-6" />
    <IconPaymentMastercard class="h-6" />
    <IconPaymentAmericanExpress class="h-6" />
    <IconPaymentPostFinance class="h-6" />
    <IconPaymentApplePay class="h-6" />
    <IconPaymentRechnung class="h-6" />
    <IconPaymentCoupon class="h-6" />
  </div>
</template>
